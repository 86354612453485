.service-teaser-grid {
  margin: 64px -32px 0px !important;

  > * {
    padding: 0px 32px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 2) {
        margin-top: 0px;
      }
    }
  }
}

.service-teaser-icon {
  display: inline-block;
  margin-bottom: 24px;
}

.project-teaser-grid {
  margin: 64px -8px !important;

  > * {
    padding: 0px 8px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 2) {
        margin-top: 0px;
      }
    }
  }
}

.project-teaser {
  display: inline-block;
  overflow: hidden;

  @include media-query(lg) {
    position: relative;

    &:hover {
      .project-teaser-image {
        img {
          transform: scale(1.25);
        }
  
        &:before {
          background-color: rgba($color-black, .75);
        }
      }
  
      .project-teaser-body {
        margin-bottom: -1rem;

        p {
          max-height: 100%;
        }
      }
    }
  }
}

.project-teaser-image {
  position: relative;
  z-index: 0;
  overflow: hidden;

  img {
    transition: all .15s ease-in;
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, .15);
    transition: all .15s ease-in;
  }
}

.project-teaser-body {
  margin-top: 24px;

  @include media-query(lg) {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 48px;
    margin-top: 0px;
    margin-bottom: -2rem;
    color: #fff;

    p {
      max-height: 0px;
      overflow: hidden;
    }
  }
}

.project-teaser-grid-cta {
  text-align: center;
}

.project-teaser-action {
  @include media-query(lg) {
    display: none;
  }
}

.button-link {
  display: inline-block;
  font-size: rem(18px);
  font-weight: $fw-bold;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    margin: 0px auto;
    background-color: $color-primary;
    transition: all .15s ease-in;
  }

  &:hover {
    &:after {
      width: 0px;
    }
  }
}