/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */

 #baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba($color-black, .8);
  transition: opacity .5s ease;

  &.visible {
      opacity: 1;
  }

  .full-image {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      figure {
          display: inline;
          margin: 0;
          height: 100%;
      }

      img {
          display: inline-block;
          width: auto;
          height: auto;
          max-height: 100%;
          max-width: 100%;
          vertical-align: middle;
      }

      figcaption {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          line-height: 1.8;
          white-space: normal;
          color: #ccc;
          background-color: #000;
          background-color: rgba(0,0,0,.6);
          font-family: sans-serif;
      }

      &:before {
          content: "";
          display: inline-block;
          height: 50%;
          width: 1px;
          margin-right:-1px;
      }
  }
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease;

  &.bounce-from-right {
      animation: bounceFromRight .4s ease-out;
  }

  &.bounce-from-left {
      animation: bounceFromLeft .4s ease-out;
  }
}

@keyframes bounceFromRight{
  0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; }
}

@keyframes bounceFromLeft{
  0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; }
}

%arrow-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: transparent;
  color: #fff;
  font: 1.6em sans-serif;
  transition: background-color .4s ease;

  &#next-button {
      @extend %arrow-button;
      right: 2%;
  }

  &#previous-button {
      @extend %arrow-button;
      left: 2%;
  }

  &#close-button {
      top: 20px;
      right: 2%;
      right: calc(2% + 6px);
      width: 30px;
      height: 30px;
  }

  svg {
      position: absolute;
      left: 0;
      top: 0;
  }
}

.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
      transform: scale(0);
  } 50% {
      transform: scale(1);
  }
}
