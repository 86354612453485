.nav-primary {
  display: none;
  margin-left: auto;

  @include media-query(lg) {
    display: inline-block;
  }
}

.nav-primary-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    margin-left: 36px;

    &:first-child {
      margin-left: 0px;
    }
  }

  a {
    font-size: rem(18px);
    font-weight: $fw-bold;
    color: $color-black;
    transition: all .15s ease-in;

    &:after {
      content: '';
      display: block;
      width: 0px;
      height: 3px;
      margin: 0px auto;
      background-color: $color-primary;
      transition: all .15s ease-in;
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }

    &.is-active {
      &:after {
        width: 100%;
      }
    }
  }
}

.nav-secondary {
  display: none;
  margin-left: auto;

  @include media-query(lg) {
    display: inline-block;
  }
}

.nav-secondary-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    margin-left: 32px;

    &:first-child {
      margin-left: 32px;
    }
  }

  a {
    font-size: rem(14px);
    color: #fff;
    transition: all .15s ease-in;

    &:hover,
    &.is-active {
      color: $color-primary;
    }
  }
}

.nav-footer-panel {
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    margin-top: 8px;

    &:first-child {
      margin-top: 0px;
    }
  }

  a {
    font-size: rem(18px);
    transition: all .15s ease-in;

    &:hover,
    &.is-active {
      color: $color-primary;
    }
  }
}

.nav-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  @include media-query(lg) {
    display: none;
  }
}

.nav-mobile-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  text-indent: -999rem;

  &:hover {
    cursor: pointer;
  }
  
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 26px;
    height: 2px;
    background-color: $color-black;

    &:before {
      content: '';
      display: block;
      width: 26px;
      height: 2px;
      background-color: $color-black;
      position: absolute;
      top: -8px;
      left: 0px;
    }

    &:after {
      content: '';
      display: block;
      width: 26px;
      height: 2px;
      background-color: $color-black;
      position: absolute;
      bottom: -8px;
      left: 0px;
    }
  }

  &.close {
    position: absolute;
    top: 0px;
    right: 0px;
    i {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        top: 0px;
      }

      &:after {
        transform: rotate(-45deg);
        bottom: 0px;
      }
    }
  }
}

.nav-mobile-panel {
  position: fixed;
  z-index: 99;
  top: 0px;
  left: -100%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0px 0px 64px rgba($color-black, .15);
  transition: all .25s ease-out,
      top 0s .25s;

  &.is-active {
    left: 0px;
    opacity: 1;
    transition: all .25s ease-out,
      top 0s;
  }
}

.nav-mobile-primary-panel {
  padding: 32px 80px 16px 32px;
  margin: 0px;

  li {
    list-style: none;
    margin-top: 8px;

    &:first-child {
      margin-top: 0px;
    }
  }

  a {
    font-size: rem(24px);
    font-weight: $fw-bold;
    color: $color-silver;
    transition: all .15s ease-in;

    &:hover,
    &.is-active {
      color: $color-black;
    }
  }
}

.nav-mobile-secondary-panel {
  padding: 16px 80px 32px 32px;
  margin: 0px;

  li {
    list-style: none;
    margin-top: 2px;

    &:first-child {
      margin-top: 0px;
    }
  }

  a {
    font-size: rem(18px);
    font-weight: $fw-bold;
    color: $color-silver;
    transition: all .15s ease-in;

    &:hover,
    &.is-active {
      color: $color-black;
    }
  }
}

.nav-legal {
  display: inline-block;
  margin-right: auto;
}

.nav-legal-panel {
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    
  }

  a {
    transition: all .15s ease-in;
    
    &:hover {
      color: $color-primary;
    }
  }

  @include media-query(lg) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    li {
      margin-left: 16px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

.nav-labels-panel {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px -2px;

  li {
    list-style: none;
    padding: 2px 2px;
  }

  a {
    display: inline-block;
    padding: 8px 24px;
    background-color: $color-black;
    font-weight: $fw-bold;
    color: #fff;
    transition: all .15s ease-in;

    &:hover,
    &.is-active {
      background-color: $color-primary;
      color: $color-black;
    }
  }
}