.header {
  width: 100%;
}

.header-top {
  padding: 8px 0px;
  background-color: $color-black;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.header-contact-methods {
  margin-left: auto;

  @include media-query(lg) {
    margin-left: 0px;
    margin-right: auto;
  }
}

.header-contact-method {
  display: inline-block;
  margin-left: 16px;
  font-size: rem(14px);
  color: #fff;

  &:first-child {
    margin-left: 0px;
  }

  a {
    display: inline-block;
  }

  span {
    display: none;
  }

  @include media-query(lg) {
    span {
      margin-left: 4px;
      display: inline-block;
    }
  }
}

.header-bottom {
  padding: 16px 0px;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.header-logo {
  margin-right: auto;
}