/*
 * Config
 */

$color-primary: #fbc723;
$color-black: #232323;
$color-silver: #97989b;
$color-clouds: #f6f6f6;

$fw-normal: 400;
$fw-bold: 700;