.footer {
  padding: 48px 0px;
  background-color: $color-black;
  font-size: rem(18px);
  color: #fff;

  @include media-query(lg) {
    padding: 128px 0px;
  }
}

.footer-grid {
  margin: 0px -32px !important;

  > * {
    padding: 0px 32px !important;
    margin-top: 64px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n + 3) {
        margin-top: 0px;
      }
    }
  }
}

.footer-spacer {
  display: block;
  margin: 32px 0px;
  border-top: 1px solid lighten($color-black, 10%);
}

.footer-legal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: rem(14px);
  color: $color-silver;
}

.footer-copyright {
  margin-left: auto;
}