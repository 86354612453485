.gallery-grid {
  margin: 64px -8px 0px !important;

  > * {
    padding: 0px 8px !important;
    margin-top: 16px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(sm) {
    > * {
      &:nth-child(-n+2) {
        margin-top: 0px;
      }
    }
  }

  @include media-query(lg) {
    > * {
      &:nth-child(-n+3) {
        margin-top: 0px;
      }
    }
  }
}