.button {
  width: 100%;
  font-size: rem(20px);
  min-height: 56px;
  padding: 12px 24px;
  border-radius: 0px;
  transition: all .15s ease-in;

  svg {
    margin-left: 12px;
  }

  @include media-query(xs) {
    width: auto;
  }
}

.button-primary {
  background-color: $color-primary;
  color: $color-black;

  &:hover {
    background-color: lighten($color-primary, 5%);
  }
}

.button-link {
  display: inline-block;
  font-size: rem(18px);
  font-weight: $fw-bold;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    margin: 0px auto;
    background-color: $color-primary;
    transition: all .15s ease-in;
  }

  &:hover {
    &:after {
      width: 0px;
    }
  }
}