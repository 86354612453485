/*
 * Config
 */

$base-font-family: Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: normal;
$base-font-color: #000;

$color-error: #fa7e63;

$headings: (
  h1: 48px,
  h2: 36px,
  h3: 30px,
  h4: 24px,
  h5: 18px,
  h6: 16px
);

$base-background-color: #fff;

$icon-sizes: (
  xs: 12px,
  sm: 16px,
  md: 24px,
  lg: 32px,
  xl: 48px,
  xxl: 64px
);
 
$container-gutter: 36px;

$grid-columns: 12;
$grid-gutter: 24px;
$grid-breakpoints: (
  xs: 480px,
  sm: 640px,
  md: 800px,
  lg: 960px,
  xl: 1120px,
  xxl: 1280px
);