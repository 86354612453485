.section {
  padding: 48px 0px;

  @include media-query(lg) {
    padding: 128px 0px;
  }
}

.section-title {
  margin-bottom: 0px;

  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 5px;
    background-color: $color-primary;
    margin-top: 24px;
  }
}

.section-clouds {
  background-color: $color-clouds;
}

.section-background {
  position: relative;
  z-index: 0;
  background-color: #fafafa;
  background-size: cover;
  color: #fff;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, .15);
  }
}