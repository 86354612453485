.usp-overview {
  padding: 48px;
  background-color: $color-clouds;

  ul {
    font-size: rem(18px);
    padding: 0px;
  }

  li {
    list-style: none;
    margin-top: 8px;

    &:first-child {
      margin-top: 0px;
    }

    &:before{
      content: '';
      display: inline-block;
      width: 8px;
      height: 16px;
      border: solid $color-primary;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      margin-right: 16px;
    }
  }

  .button {
    width: 100%;
  }
}