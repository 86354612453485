.icon-primary {
  fill: $color-primary;
}

.icon-black {
  fill: $color-black;
}

.icon-silver {
  color: $color-silver;
}