.logo {
  width: 160px;
  height: 56px;

  @include media-query(lg) {
    width: 210px;
    height: 73px;
  }
}

.logo-link {
  display: inline-block;
  vertical-align: middle;
}