/*
 * Grid
 */

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px -#{$grid-gutter/2};
}

.grid-column {
  @for $i from 1 through $grid-columns {
    &-#{$i} {
      width: #{100%/$grid-columns*$i};
      padding: 0px #{$grid-gutter/2};
    }
  }
  
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-query($breakpoint) {
      @for $i from 1 through $grid-columns {
        &-#{$i}-#{$breakpoint} {
          width: #{100%/$grid-columns*$i};
          padding: 0px #{$grid-gutter/2};
        }
      }
    }
  }

  &-offset {
    @for $i from 1 through $grid-columns {
      &-#{$i} {
        margin-left: 100/$grid-columns*$i*1%;
      }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-query($breakpoint) {
        @for $i from 1 through $grid-columns {
          &-#{$i}-#{$breakpoint} {
            margin-left: 100/$grid-columns*$i*1%;
          }
        }
      }
    }
  }
}
